import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

let HomeLostPhone = lazy(() => import("./HomeLostPhone"));

export async function lazyHomeLostPhoneLoader() {
    const componentModule = await import("./HomeLostPhone");
    // This avoid flicker from React.lazy by using the component directly
    HomeLostPhone = componentModule.default as any;

    return null;
}

export function LazyHomeLostPhone() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t("loading")}>
            <HomeLostPhone />
        </Suspense>
    );
}
