import { FC, Fragment } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface TypographyWithHighlightProps extends TypographyProps {
    text: string;
    highlight: string;
    highlightProps?: TypographyProps;
}

const TypographyWithHighlight: FC<TypographyWithHighlightProps> = ({ text, highlight, highlightProps, ...typographyProps }) => {
    const highlightedTextRegex = new RegExp(`(${highlight})`, "g");

    const parts = text.split(highlightedTextRegex);

    return (
        <Typography {...typographyProps}>
            {parts.map((part, index) =>
                part === highlight ? (
                    <Typography key={index} component="span" variant="inherit" display="inline" {...highlightProps}>
                        {part}
                    </Typography>
                ) : (
                    <Fragment key={index}>{part}</Fragment>
                )
            )}
        </Typography>
    );
};

export default TypographyWithHighlight;
