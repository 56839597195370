export enum Languages {
    en = "en",
    fr = "fr",
    es = "es",
    pt = "pt",
    cn = "cn",
    de = "de",
    vn = "vn",
    tr = "tr",
}

export enum Locales {
    en = "en-US",
    fr = "fr-FR",
    es = "es-ES",
    pt = "pt-PT",
    cn = "zh-CN",
    de = "de-DE",
    vn = "vi-VN",
    tr = "tr-TR",
}

export const Countries: Record<string, string[]> = {
    fr: ["FR"],
    es: ["ES", "AR", "BO", "CL", "CO", "EC", "GY", "PY", "PE", "SR", "UY", "VE", "MX"],
    pt: ["PT", "BR"],
    cn: ["CN", "TW", "SG"],
    de: ["DE", "AT", "CH"],
    vn: ["VN"],
    tr: ["TR"],
};
