import { Box, Stack, StackProps, styled, SxProps, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { themeColors } from "constants/colors.const";
import { useLanguageDetect } from "hooks/useLanguageDetect";

interface Props {
    imageUrl: string;
    title: string;
    subtitle: string;
    sx?: SxProps;
    children?: ReactNode;
}

interface BadgeWrapProps extends StackProps {
    children: ReactNode;
}

const BadgeWrap = styled(Stack)<BadgeWrapProps>(({ theme }) => ({
    padding: "8px 18px 8px 8px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.brandColors.brandLightgrey}`,
    alignItems: "center",
    maxWidth: "calc(50% - 10px)",
}));

export const IconTextBadge: FC<Props> = ({ imageUrl, title, subtitle, sx }) => {
    const { isFrenchLanguage, isGermanLanguage } = useLanguageDetect();

    return (
        <BadgeWrap sx={sx} direction="row" gap="8px">
            <Box sx={{ width: "40px", height: "40px" }}>
                <img src={imageUrl} alt={title} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Stack sx={{ width: "calc(100% - 48px)" }} gap={0}>
                <Typography
                    variant="body1Medium"
                    color={themeColors.text.tertiary}
                    whiteSpace="nowrap"
                    sx={{
                        fontSize: {
                            xs: isFrenchLanguage ? "17px" : undefined,
                            md: "22px",
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="smallThin"
                    fontSize={isGermanLanguage ? "8px" : "11px"}
                    color={themeColors.text.tertiary}
                    whiteSpace="nowrap"
                    sx={{
                        fontSize: { xs: isGermanLanguage ? "8px" : "11px" },
                    }}
                >
                    {subtitle}
                </Typography>
            </Stack>
        </BadgeWrap>
    );
};
