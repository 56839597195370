import { isToday } from "helpers/isToday";
import { getLocations } from "http-client/location.client";
import { ILocation } from "interfaces/location/location.interface";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./root.store";

export class LocationsStore {
    rootStore: RootStore;
    locations: ILocation[] = [];
    location: ILocation | null = null;
    limit = 24;
    overLimit = false;
    loading = false;
    private dailyLocationsSet = new Set();

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            locations: observable,
            location: observable,
            loading: observable,
            overLimit: observable,
            setLocations: action,
            setLocation: action,
            setLoading: action,
        });
        this.rootStore = rootStore;
    }

    setLocation = (location: ILocation | null) => {
        this.location = location;
    };

    setLocations = (locations: ILocation[]) => {
        this.locations = locations;
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    fetchLocations = async (...payload: Parameters<typeof getLocations>) => {
        this.setLoading(true);
        const locations = await getLocations(...payload);
        this.updateDailyLocations(locations);
        this.setLocations(locations || []);
        this.checkLimit();
        this.setLoading(false);
    };

    fetchAllLocations = async () => {
        const locations = await getLocations();
        this.updateDailyLocations(locations);
        this.checkLimit();
    };

    updateDailyLocations = (locations: ILocation[]) => {
        locations?.forEach((location) => {
            if (isToday(location.createdAt)) {
                this.dailyLocationsSet.add(location.id);
            }
        });
    };

    checkLimit = () => {
        this.overLimit = this.dailyLocationsSet.size >= this.limit;
    };

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.locations) {
            this.locations = data.locations;
        }
    };
}
